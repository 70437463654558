/* App.css */
:root {
  --dot-color-red: #e61000;
  --dot-color-yellow: #f3c003;
  --dot-color-green: #00c853;
  --eidra-blue-dark: #002643;
  --eidra-blue-light: #0b64f9;
}

body {
  background-color: #F0E9E3;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h1 {
  text-align: center;
  color: var(--eidra-blue-dark);
  margin: 1.5rem 0;
  padding-bottom: 1rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.1;
  font-family: Arial, sans-serif;
}

.eidra-logo {
  max-width: 300px;
  /* Adjust this value as needed */
  height: auto;
  display: block;
  margin: 0 auto 1rem;
}

h1:not(.faq-title)::after {
  content: "Competence Portal";
  display: block;
  font-size: 2.5rem;
  font-weight: 500;
  letter-spacing: -0.5px;
  font-family: Arial, sans-serif;
  color: #000000;
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.search-bar {
  grid-column: 1 / -1;
  margin-bottom: 0.2rem;
}

.search-bar input {
  width: 100%;
  padding: 0.6rem 1.5rem;
  font-size: 1.1rem;
  border: 2px solid #e0e0e0;
  border-radius: 14px;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
  box-sizing: border-box;
}

.search-bar input:focus {
  outline: none;
  border-color: var(--eidra-blue-light);
  box-shadow: 0 0 0 3px rgba(11, 100, 249, 0.1);
}

.filters {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 2rem;
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin: 0 auto;
  width: calc(100% - 92px);
}

.regular-filters {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;
}


.filter-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.filter-group label {
  font-weight: 600;
  color: black;
  font-size: 0.9rem;
  text-transform: none;
  letter-spacing: 0.5px;
}

.react-select__control {
  border: 2px solid #e0e0e0 !important;
  border-radius: 8px !important;
  min-height: 42px !important;
  box-shadow: none !important;
  transition: all 0.2s ease !important;
}

.react-select__control:hover {
  border-color: var(--eidra-blue-light) !important;
}

.react-select__control--is-focused {
  border-color: var(--eidra-blue-light) !important;
  box-shadow: 0 0 0 3px rgba(11, 100, 249, 0.1) !important;
}

button {
  padding: 10px 20px;
  background-color: #00c853;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-start;
}

button:hover {
  background-color: #45a049;
}

.results {
  max-width: 1200px;
  width: calc(100% - 92px);
  margin: 0px 13px;
  margin-top: 20px;
}

.results>div {
  margin-bottom: 10px;
}

.results>div>a {
  color: #007bff;
  text-decoration: none;
}

.results>div>a:hover {
  text-decoration: underline;
}

.result-item {
  display: grid;
  grid-template-columns: 1.4fr 2fr 1.9fr;
  gap: 24px;
  background-color: #FDFBF9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 32px;
  margin: 0 0 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: none;
  width: 100%;
}

.result-info {
  width: auto;
}

.result-skills {
  justify-self: end;
  border-left: 1px solid #eee;
  padding-left: 24px;
  min-width: 0;
}

.result-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.result-content {
  margin-right: 100px;
  /* Make space for the button */
}

.result-link {
  position: absolute;
  bottom: 20px;
  right: 32px;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.result-item:hover .result-link {
  opacity: 1;
}

.result-item h3 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #333;
  font-size: 1.2em;
}

.result-item a {
  text-decoration: none;
  color: #007bff;
}

.result-item a:hover {
  text-decoration: underline;
}

.result-item p {
  margin: 5px 0;
}

.result-item strong {
  color: var(--eidra-blue-dark);
  font-weight: 600;
}



.result-link a {
  display: inline-block;
  padding: 8px 15px;
  background-color: var(--eidra-blue-light);
  color: #fff;
  text-decoration: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
  margin-bottom: 20px;
}

.result-link a:hover {
  background-color: #0056b3;
  text-decoration: none;
}

.skill-dots {
  display: inline-flex;
  margin-left: 10px;
}

.skill-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.skill-name {
  width: 200px;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.2;
  margin-right: 8px;
  color: var(--eidra-blue-dark);
}

.skill-name.highlighted {
  color: var(--eidra-blue-light);
  font-weight: 600;
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 2px;
}

.dot.filled {
  background-color: #002643;
}

.dot.empty {
  background-color: #D9D9D9;
}

.availability-container {
  display: flex;
  align-items: center;
  margin-left: 0;
  overflow-x: auto;
  padding-bottom: 8px;
  /* Space for scrollbar */
  max-width: 520px;
  /* Adjust based on your layout */
  scrollbar-width: thin;
  /* Firefox */
  -ms-overflow-style: -ms-autohiding-scrollbar;
  /* IE/Edge */
}

.availability-dot-container {
  display: inline-block;
  margin-right: 2px;
  flex-shrink: 0;
  /* Prevent dots from shrinking */
}

.availability-dot {
  width: 48px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-size: 14px;
  font-weight: 500;
  flex-shrink: 0;
  /* Prevent dots from shrinking */
}

/* Remove the week-number styles since we'll put the number inside the square */
.week-number {
  display: none;
}

.red-dot {
  background-color: #ffcdd2;
}

.yellow-dot {
  background-color: #fff3c4;
}

.green-dot {
  background-color: #c8e6c9;
}

.sort-options select {
  border-radius: 12px;
  border: 1px solid #ccc;
  padding: 6px;
  font-size: 1rem;
}

.pulsate {
  background: linear-gradient(-45deg, #dddddd, #f0f0f0, #dddddd, #f0f0f0);
  background-size: 400% 400%;
  -webkit-animation: Gradient 2.25s ease infinite;
  -moz-animation: Gradient 2.25s ease infinite;
  animation: Gradient 2.25s ease infinite;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.week-range-container {
  display: flex;
  align-items: center;
}

.week-range-input {
  margin-right: 5px;
  margin-left: 5px;
  border: 2px solid #e0e0e0;
  padding: 0.3rem;
  color: #333;
  border-radius: 12px;
}

.week-range-separator {
  margin: 0 5px;
}

.availability-buttons-container {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.availability-button {
  flex: 1;
  padding: 0.75rem;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  font-weight: 500;
  background-color: white;
  color: #333;
  transition: all 0.2s ease;
}

.availability-button:hover {
  background-color: #e6f0ff;
  border-color: #4d94ff;
}

.availability-button.selected {
  border-color: #4d94ff;
  background-color: #e6f0ff;
}

.availability-dots {
  display: flex;
  gap: 2px;
}

.availability-indicator-dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.availability-indicator-dot.red-dot {
  background-color: var(--dot-color-red);
}

.availability-indicator-dot.yellow-dot {
  background-color: var(--dot-color-yellow);
}

.availability-indicator-dot.green-dot {
  background-color: var(--dot-color-green);
}

/* Add these new navbar styles */
.navbar {
  background-color: #002643;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 24px;
  display: flex;
  align-items: center;
}

.navbar a {
  color: white;
  text-decoration: none;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 0;
  transition: background-color 0.2s ease;
}

.navbar a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.logout-button {
  background-color: var(--eidra-blue-light);
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.logout-button:hover {
  background-color: #cc0000;
}

/* Update the existing .App class */
.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Add this exception for the navbar */
.navbar.App {
  max-width: 100%;
  margin: 0;
  padding: 1rem 2rem;
}

button[type="submit"] {
  background-color: var(--eidra-blue-light);
  color: white;
  padding: 1rem 2.5rem;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: inline-block;
  box-shadow: 0 2px 4px rgba(11, 100, 249, 0.1);
}

button[type="submit"]:hover {
  background-color: #0952d9;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(11, 100, 249, 0.2);
}

button[type="submit"]:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(11, 100, 249, 0.1);
}

/* Add these new styles */
.industries-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 4px;
  max-width: 70%;
}

.industry-tag {
  background-color: #f0f0f0;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.9rem;
}

.industry-tag.highlighted {
  background-color: #e6f0ff;
  color: var(--eidra-blue-light);
}

.card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eee;
}

.skill-container {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.skill-name {
  width: 260px;
  white-space: normal;
  word-wrap: break-word;
  line-height: 1.2;
  margin-right: 8px;
}

.skill-dots {
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.dot {
  height: 12px;
  width: 12px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 2px;
  flex-shrink: 0;
}


.skill-name.highlighted {
  color: var(--eidra-blue-light);
  opacity: 0.85;
  /* Makes the color slightly more subtle */
}

/* Consistent header styling */
.result-skills strong {
  color: var(--eidra-blue-dark);
  font-weight: 600;
}

/* Better industries spacing */
.industries-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 6px;
  margin-bottom: 2px;
  /* Add space before availability section */
}

.result-info p {
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 12px;
  /* Increased from 8px */
  margin: 8px 0;
  /* Increased from 5px */
  align-items: baseline;
  font-size: 1.05rem;
  /* Slightly larger font */
}

.result-info strong {
  color: var(--eidra-blue-dark);
  font-weight: 600;
}

/* Add styles for the value part */
.result-info p span {
  color: #333;
  /* Darker than default text */
  font-weight: 500;
  /* Slightly bolder */
}

/* Add more space before Industries section */
.result-info .industries-section {
  margin-top: 16px;
}

/* Basic info section (Name, Company, Track, Seniority) */
.result-info p {
  display: grid;
  grid-template-columns: 120px 1fr;
  gap: 12px;
  margin: 8px 0;
  align-items: baseline;
  font-size: 1.05rem;
}

.result-info strong {
  color: var(--eidra-blue-dark);
  font-weight: 600;
}

.result-info p span {
  color: #333;
  font-weight: 500;
}

/* Add separation between sections */
.basic-info-section {
  margin-bottom: 24px;
  /* Space after basic info */
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
  /* Light separator line */
}

/* Industries section */
.industries-section {
  margin-bottom: 24px;
}

.industries-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 8px;
}

/* Availability section */
.availability-filter-section {
  margin-top: 24px;
  border-left: 1px solid #ddd;
  /* Match the result cards border color */
  padding-left: 2rem;
}

/* Availability section header */
.availability-section strong {
  white-space: nowrap;
  /* Keeps "Availability upcoming weeks" on one line */
}

/* Availability container */
.availability-container {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.availability-dot-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}

.welcome-text {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 500;
  letter-spacing: -0.5px;
  font-family: Arial, sans-serif;
  color: #000000;
  margin-bottom: 1rem;
  display: block;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.login-text {
  font-size: 1.2rem;
  margin: 1.5rem 0;
}

.login-button {
  margin: 1rem 0;
}

.construction-text {
  color: var(--dot-color-red);
  font-size: 0.9rem;
  margin-top: 1rem;
  font-style: italic;
  background-color: #E5DED7;
  /* Slightly darker than #F0E9E3 */
  padding: 0.5rem 1rem;
  border-radius: 4px;
}

.navbar a.active {
  border-bottom: 2px solid var(--dot-color-green);
}

.navbar a.active:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

/* Add these new styles */
.consultant-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
}

.consultant-name {
  font-size: 1.4rem;
  font-weight: 600;
  color: var(--eidra-blue-dark);
  margin: 0;
}

.rating {
  display: flex;
  align-items: center;
  gap: 2px;
  color: var(--eidra-blue-dark);
  font-weight: 600;
}

.star {
  color: #FFD700;
  /* Golden color for the star */
}

.company-info {
  color: #666;
  font-size: 0.95rem;
  margin-bottom: 0px;
}

/* Update existing styles */
.result-info {
  width: 530px;
  /* Adjust basic info + industries width of result card */
}

.basic-info-section {
  margin-top: 16px;
  /* Add some space after the header */
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
}

.seniority-container {
  display: flex;
  align-items: center;
  gap: 12px;
}

.seniority-wrapper {
  position: relative;
  width: 200px;
  height: 24px;
  background-color: #f0f0f0;
  border-radius: 12px;
  overflow: hidden;
}

.seniority-bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: var(--progress);
  background: linear-gradient(90deg, var(--eidra-blue-light) 0%, var(--eidra-blue-dark) 100%);
  transition: width 0.3s ease;
}

.seniority-label {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-weight: 500;
  font-size: 0.9rem;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  z-index: 1;
}

.seniority-info {
  color: #666;
  font-size: 0.85rem;
  margin-top: 0px;
  font-weight: 500;
}

.last-cv-update {
  color: #888;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 6px;
  position: absolute;
  padding-right: 32px;
  bottom: 20px;
  right: 0;
}

.calendar-icon {
  color: #888;
  font-size: 0.9rem;
}

/* Update FAQ page styles */
.faq-container {
  max-width: 800px;
  margin: 2rem auto;
}

.faq-item {
  background: white;
  border-radius: 12px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.faq-question {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: white;
  border: none;
  width: 100%;
  text-align: left;
  transition: background-color 0.2s ease;
}

.faq-question:hover {
  background-color: #f8f9fa;
}

.faq-question h3 {
  margin: 0;
  font-size: 1.1rem;
  color: var(--eidra-blue-dark);
  font-weight: 500;
}

.faq-answer {
  padding: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.faq-answer.open {
  padding: 0 1.5rem 1.5rem;
  max-height: 500px;
  /* Adjust this value based on your content */
}

.faq-answer p {
  margin: 0;
  color: #666;
  line-height: 1.6;
}

.faq-answer ul {
  margin: 1rem 0;
  padding-left: 1.5rem;
}

.faq-answer li {
  margin-bottom: 0.5rem;
  color: #666;
}

.chevron {
  transition: transform 0.3s ease;
  color: #333;
  font-size: 0.8rem;
}

.chevron.open {
  transform: rotate(180deg);
}

/* Update the info/construction text styling */
.construction-text {
  background-color: white;
  border-left: 4px solid var(--eidra-blue-light);
  color: #666;
  font-size: 0.95rem;
  line-height: 1.5;
  margin: 2rem auto;
  max-width: 800px;
  padding: 1.25rem 1.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.week-range-container span {
  font-size: 0.85rem;
  color: #666;
}

.week-range-separator {
  font-size: 0.85rem;
  color: #666;
}

.css-13cymwt-control {
  /* This is the default React Select control class */
  border: 2px solid #e0e0e0 !important;
  border-radius: 14px !important;
  min-height: 38px !important;
  box-shadow: none !important;
  transition: all 0.2s ease !important;
}

/* Style for the control (the main input box) */
.css-13cymwt-control {
  border: 2px solid #e0e0e0 !important;
  border-radius: 14px !important;
  min-height: 38px !important;
  box-shadow: none !important;
  transition: all 0.2s ease !important;
}

/* Style for when the select is focused/menu is open */
.css-t3ipsp-control {
  border: 2px solid var(--eidra-blue-light) !important;
  border-radius: 14px !important;
  box-shadow: 0 0 0 3px rgba(11, 100, 249, 0.1) !important;
}

/* Style for the menu (dropdown) */
.css-1nmdiq5-menu {
  border-radius: 14px !important;
  margin-top: 8px !important;
  /* Add some space between control and menu */
  overflow: hidden !important;
}

/* Add new results-header style and update sort-options */
.results-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  padding-top: 20px;
  padding-right: 0px;
  padding-left: 20px;
  width: 1152px;
}

.results-header h2 {
  margin: 0;
  align-self: flex-end;
  padding-left: 10px;
  padding-bottom: 0px;
}

.sort-options {
  align-self: flex-end;
}

.sort-label {
  align-self: flex-end;
  padding-bottom: 7px;
  font-size: 1rem;
}

.sort-container {
  display: flex;
  align-items: center;
  align-self: flex-end;
  /* Align the sort container to the bottom */
  gap: 8px;
}

.availability-filter-section {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.availability-filter-section button[type="submit"] {
  margin-top: 1rem;
  width: 100%;
}

/* If there's still space, you might need to adjust the parent container */
.availability-section {
  grid-column: 1 / -1;
  margin-top: 1px;
  padding-top: 1px;
}

/* Custom scrollbar styling for Webkit browsers */
.availability-container::-webkit-scrollbar {
  height: 6px;
}

.availability-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.availability-container::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 3px;
}

.availability-container::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.profile-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.profile-card {
  background: white;
  border-radius: 12px;
  padding: 32px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-header {
  margin-bottom: 24px;
  border-bottom: 1px solid #eee;
  padding-bottom: 16px;
}

.profile-header h2 {
  margin: 0;
  color: var(--eidra-blue-dark);
  font-size: 1.8rem;
}

.profile-company {
  color: #666;
  margin: 8px 0 0;
  font-size: 1.1rem;
}

.profile-section {
  margin-bottom: 32px;
}

.profile-section h3 {
  color: var(--eidra-blue-dark);
  margin-bottom: 16px;
  font-size: 1.3rem;
}

.profile-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.info-item strong {
  color: #666;
  font-size: 0.9rem;
}

.skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 16px;
}

.tools-grid,
.industries-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tool-tag,
.industry-tag {
  background: #f0f0f0;
  padding: 6px 12px;
  border-radius: 16px;
  font-size: 0.9rem;
  color: #333;
}

/* Profile page specific availability styles */
.profile-card .availability-container {
  margin: 16px 0;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
}

.profile-card .last-cv-update {
  position: static;
  margin-top: 8px;
  color: #888;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 6px;
}

.profile-card .calendar-icon {
  color: #888;
  font-size: 0.9rem;
}

.no-results {
  background: white;
  border-radius: 12px;
  padding: 3rem;
  text-align: center;
  margin: 2rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.no-results-icon {
  font-size: 3rem;
  margin-bottom: 1rem;
  opacity: 0.7;
}

.no-results h3 {
  color: var(--eidra-blue-dark);
  font-size: 1.5rem;
  margin: 0 0 0.5rem;
}

.no-results p {
  color: #666;
  font-size: 1.1rem;
  margin: 0;
  line-height: 1.5;
}

/* Add these styles for the warning message */
.warning-message {
  background-color: #fff3c4;
  /* Light yellow background */
  color: #333;
  /* Dark text for contrast */
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.warning-symbol {
  margin-right: 0.5rem;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

/* Add styles for the experiences column */
.result-experiences {
  border-left: 1px solid #eee;
  padding-left: 24px;
  min-width: 0;
}

.experience-item {
  position: relative;
  color: var(--eidra-blue-dark);
  font-size: 1rem;
  margin-bottom: 4px;
  white-space: normal;
  /* Allow text to wrap */
  word-wrap: break-word;
  /* Break long words if needed */
}

.experience-item::before {
  position: absolute;
  left: 0;
  color: var(--eidra-blue-dark);
}

.result-experience-item {
  position: relative;
  padding: 4px 0 4px 8px;
  /* Add left padding for bullet point */
  color: var(--eidra-blue-dark);
  font-size: 0.9rem;
  margin-bottom: 1px;
  white-space: normal;
  /* Allow text to wrap */
  word-wrap: break-word;
  /* Break long words if needed */
}

.result-experience-item::before {
  content: "-";
  /* Add bullet point */
  position: absolute;
  left: 0;
  color: var(--eidra-blue-dark);
}

/* Update the column separators */
.result-skills,
.result-experiences {
  position: relative;
}

.result-skills::before,
.result-experiences::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  /* Can now be full height since availability is below */
  width: 1px;
  background-color: #eee;
}

/* Remove the old border styling */
.result-skills,
.result-experiences {
  padding-left: 24px;
  min-width: 0;
  border-left: none;
}

/* Profile header modifications */
.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

.profile-header-content {
  flex: 1;
}

.create-cv-button {
  padding: 0.5rem 1rem;
  background-color: var(--eidra-blue-light);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.create-cv-button:hover {
  background-color: var(--eidra-blue-dark);
}

/* CV Form Overlay */
.cv-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  overflow-y: auto;
  padding: 2rem 0;
}

.cv-form {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: auto;
}

.cv-form h3 {
  margin-top: 0;
  margin-bottom: 1.5rem;
  color: var(--eidra-blue-dark);
}

.create-cv-submit {
  margin-top: 1.5rem;
  width: 100%;
  padding: 0.75rem;
  background-color: var(--eidra-blue-light);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.create-cv-submit:hover {
  background-color: var(--eidra-blue-dark);
}

.close-form {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  font-size: 1.25rem;
  cursor: pointer;
  color: #666;
  padding: 0.5rem;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
}

.close-form:hover {
  background-color: #ffebee;
  /* Light red background */
  color: #dc3545;
  /* Red text */
}

/* Add these new styles */
.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 500;
}

.checkbox-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 0.5rem;
  max-height: 200px;
  overflow-y: auto;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}

.checkbox-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: #333;
  cursor: pointer;
}

.checkbox-label input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

/* Update existing cv-form style */
.cv-form {
  background-color: white;
  padding: 2rem;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  /* Increased from 500px to accommodate checkboxes better */
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Add these new styles for form inputs */
.form-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 0.9rem;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.form-input:focus {
  outline: none;
  border-color: var(--eidra-blue-light);
  box-shadow: 0 0 0 3px rgba(11, 100, 249, 0.1);
}

.form-textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 0.9rem;
  font-family: Arial, sans-serif;
  resize: vertical;
  min-height: 100px;
  transition: border-color 0.2s ease, box-shadow 0.2s ease;
}

.form-textarea:focus {
  outline: none;
  border-color: var(--eidra-blue-light);
  box-shadow: 0 0 0 3px rgba(11, 100, 249, 0.1);
}

/* Add these styles for form validation */
.form-input.error,
.form-textarea.error,
.checkbox-group.error {
  border-color: #dc3545;
}

.form-input.error:focus,
.form-textarea.error:focus {
  box-shadow: 0 0 0 3px rgba(220, 53, 69, 0.1);
}

.error-message {
  color: #dc3545;
  font-size: 0.85rem;
  margin-top: 0.25rem;
  display: block;
  font-weight: 500;
}

.checkbox-group.error {
  background-color: rgba(220, 53, 69, 0.05);
}

/* Profile Editor Styles */
.profile-editor-container {
  background-color: white;
  border-radius: 8px;
  padding: 2rem;
  margin: 2rem auto;
  max-width: 800px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tabs-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 1rem;
}

.tab-button {
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  color: #666;
  transition: all 0.2s ease;
}

.tab-button.active {
  color: var(--eidra-blue-dark);
  font-weight: 600;
  border-bottom: 2px solid var(--eidra-blue-dark);
}

.profile-editor-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.profile-image-section {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.profile-image-container {
  position: relative;
  width: 150px;
  height: 150px;
}

.profile-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.profile-image-input {
  display: none;
}

.profile-image-label {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--eidra-blue-dark);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s ease;
}

.profile-image-label:hover {
  background-color: var(--eidra-blue-light);
}

.profile-image-container {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #f5f5f5;
  overflow: visible;
  margin-bottom: 45px;
  z-index: 1;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border: 2px dashed #ccc;
  border-radius: 50%;
}

.profile-image-input {
  display: none;
}

.profile-image-label {
  position: absolute;
  bottom: 280px;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--eidra-blue-dark);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  white-space: nowrap;
  transition: background-color 0.2s ease;
  z-index: 10000;
}

.profile-image-label:hover {
  background-color: var(--eidra-blue-light);
}

.profile-image-section {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.profile-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.profile-image-container {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #f5f5f5;
  overflow: hidden;
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-image-placeholder {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border: 2px dashed #ccc;
  border-radius: 50%;
}

.profile-image-input {
  display: none;
}

.profile-image-label {
  background-color: var(--eidra-blue-dark);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s ease;
  z-index: 100000;
}

.profile-image-label:hover {
  background-color: var(--eidra-blue-light);
}

.notification-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.notification-card {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  text-align: center;
}

.notification-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.primary-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.secondary-button {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.profile-header-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.view-cv-button {
  padding: 8px 16px;
  background-color: #ffffff;
  color: #007bff;
  border: 1px solid #007bff;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
  text-align: center;
}

.view-cv-button:hover {
  background-color: #f8f9fa;
}

.form-error-message {
  color: #dc3545;
  text-align: center;
  margin: 10px 0;
  padding: 8px;
  border-radius: 4px;
  background-color: rgba(220, 53, 69, 0.1);
}

.cv-maker-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 2.5rem;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cv-maker-container .form-textarea {
  width: 100%;
  margin-bottom: 1.5rem;
  padding: 1.25rem;
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  font-size: 1rem;
  resize: vertical;
  min-height: 120px;
  transition: all 0.2s ease;
}

.cv-maker-container .form-textarea:focus {
  outline: none;
  border-color: var(--eidra-blue-light);
  box-shadow: 0 0 0 3px rgba(11, 100, 249, 0.1);
}

.cv-maker-container .react-select {
  width: 100%;
  margin-bottom: 2rem;
}

/* Override react-select styles */
.cv-maker-container .react-select .css-13cymwt-control {
  border: 2px solid #e0e0e0;
  border-radius: 12px;
  padding: 4px 8px;
  min-height: 50px;
}

.cv-maker-container .create-cv-submit {
  width: 100%;
  padding: 1rem;
  font-size: 1.1rem;
  background-color: var(--eidra-blue-light);
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.cv-maker-container .create-cv-submit:hover {
  background-color: #0952d9;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(11, 100, 249, 0.2);
}

.cv-maker-container .create-cv-submit:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.cv-maker-container .create-cv-submit:disabled.generating {
  background-color: var(--eidra-blue-light);
  opacity: 0.7;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.spinner {
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}